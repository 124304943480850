import React from 'react'
import { StyledRecaptchaMessage } from './styles'
import { useTranslation } from '../../../context/translationProvider'
import { useFeatureValue } from '@growthbook/growthbook-react'

const RecaptchaMessage = () => {
  const isRecaptchaEnabled = useFeatureValue('is_recaptcha_enabled')
  const { translate } = useTranslation()
  if (!isRecaptchaEnabled) return <></>

  return (
    <StyledRecaptchaMessage>
      <small
        dangerouslySetInnerHTML={{
          __html: translate('login.reCaptcha'),
        }}
      />
    </StyledRecaptchaMessage>
  )
}

export { RecaptchaMessage }
