import styled, { css } from 'styled-components'

import { themeStyles, themeMedia } from '../../../styles/theme'

import icon_carousel_right from '../../../images/icon_carousel_right_transparent_bg.svg'
import Link from '../../atoms/link'

const gapSpacing = '1em'

// Slide width is calculated by dividing 100 width the length number, this way we divide the entire StyledSlidesWrapper in even segments
// Then we subtract a few ems so it doesnt take the full segment
const calculateSlideWidth = ({ length }) =>
  css`calc(${100 / length}% - ${length > 1 ? gapSpacing : '0em'})`

// Transform position is calculated by taking the width of a slide, adding some ems (because of the gap) and then multplying it with the index
// We need the index to be a negative number so that the position goes backwards
// Lastly we the gap ems again IF its the last slide
const calculateTransformPosition = ({ length, index }) => {
  if (length == 1) return ''
  const correctionIfLastSlide = index + 1 == length ? gapSpacing : '0em'

  return css`
  translate3d(
    calc(calc(calc(${calculateSlideWidth} + ${gapSpacing}) * ${-index}) + ${correctionIfLastSlide}),
    0,
    0
  )`
}

export const StyledMobileCarouselWrapper = styled.section`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  width: 100%;
  position: relative;
  background-color: ${themeStyles.colors.black.main};
`

export const StyledSlidesWrapper = styled.div`
  transition-duration: 500ms;
  position: relative;
  display: flex;
  gap: ${gapSpacing};

  width: ${(props) => props.length * 100}%;
  transform: ${calculateTransformPosition};
`

export const StyledSlide = styled(Link)`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;

  text-align: left;
  padding: 0;
  color: ${themeStyles.colors.white.main};
  border-radius: 8px;
  overflow: hidden;

  width: ${calculateSlideWidth};
  background-color: ${(props) => props.$backgroundColor};

  //? The following lines are needed because
  //? otherwise overflow doesnt work on safari
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);

  img,
  picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const StyledImageWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  height: 220px;
  padding-bottom: 36px;

  ${themeMedia.maxMobile`
    height: ${(props) => {
      if (props.bannerHeight) return props.bannerHeight
      return props.hasTooMuchText ? 200 : 180
    }}px;
  `}
`

export const StyledWaveContainer = styled.div.attrs((props) => ({
  backgroundColor:
    props.$backgroundColor || themeStyles.colors.darkGreys[4].main,
}))`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  padding-top: 1.5em;
  position: absolute;
  bottom: 0;
  justify-content: flex-end;

  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
`

export const StyledTextCpfWrapper = styled.div`
  background: ${(props) => props.$backgroundColor};
  padding: 0.2em 0.65em 0.7em 0.65em;
`

export const StyledBottomRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const StyledNextButton = styled.div`
  width: 2em;
  height: 2em;
  cursor: pointer;
  background: url(${icon_carousel_right}) 100% no-repeat;
  background-position: center;
`
