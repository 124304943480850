import React from 'react'
import PropTypes from 'prop-types'
import { MenuSectionLi } from './styles'
import { getLinkRel } from '../../../utils/getLinkRel'
import Link from '../link'

const FooterLink = (props) => {
  const { url, title, target } = props
  return target === '_blank' ? (
    <MenuSectionLi>
      <a
        aria-current="page"
        itemProp="url"
        target={target}
        rel={getLinkRel(target)}
        title={title}
        href={url}
      >
        {title}
      </a>
    </MenuSectionLi>
  ) : (
    <MenuSectionLi>
      <Link aria-current="page" itemProp="url" title={title} to={url}>
        {title}
      </Link>
    </MenuSectionLi>
  )
}

FooterLink.defaultProps = {
  url: '',
  title: '',
  target: '',
}

FooterLink.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  target: PropTypes.string,
}

export { FooterLink }
